import Vue from 'vue';
import VueRouter from 'vue-router';
import community from '@shared/config/routes/community';
import Home from '@school/pages/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/connexion',
    name: 'login',
    meta: { checkAuth: false },
    component: () => import('@school/pages/Login.vue'),
  },
  {
    path: '/inscription/affiliation',
    name: 'register_affiliate',
    meta: { checkAuth: false },
    component: () => import('@school/pages/RegisterAffiliate.vue'),
  },
  {
    path: '/deconnexion',
    name: 'logout',
    meta: { checkAuth: true, layout: 'Blank' },
    component: () => import('@school/pages/Logout.vue'),
  },
  {
    path: '/changement-mot-de-passe',
    name: 'resetpassword',
    component: () => import('@school/pages/ResetPassword.vue'),
  },
  {
    path: '/activation',
    meta: { layout: 'Blank' },
    name: 'activation',
    component: () => import('@school/pages/Activation.vue'),
  },
  {
    path: '/formations/:slug',
    name: 'training',
    component: () => import('@school/pages/Training.vue'),
  },
  {
    path: '/formations/:slug/embed/checkout',
    name: 'trainingembed_checkout',
    meta: { layout: 'Blank', embed: true },
    component: () => import('@school/pages/embeds/Checkout.vue'),
  },
  {
    path: '/formations/:slug/embed/button',
    name: 'trainingembed_button',
    meta: { layout: 'Blank', embed: true },
    component: () => import('@school/pages/embeds/Button.vue'),
  },
  {
    path: '/formations/:slug/embed/card',
    name: 'trainingembed_card',
    meta: { layout: 'Blank', embed: true },
    component: () => import('@school/pages/embeds/Card.vue'),
  },
  {
    path: '/formations/:slug/embed/form',
    name: 'trainingembed_form',
    meta: { layout: 'Blank', embed: true },
    component: () => import('@school/pages/embeds/Form.vue'),
  },
  {
    path: '/packs/:slug',
    name: 'bundle',
    component: () => import('@school/pages/Bundle.vue'),
  },
  {
    path: '/packs/:slug/embed/checkout',
    name: 'bundleembed_checkout',
    meta: { layout: 'Blank', embed: true },
    component: () => import('@school/pages/embeds/Checkout.vue'),
  },
  {
    path: '/packs/:slug/embed/button',
    name: 'bundleembed_button',
    meta: { layout: 'Blank', embed: true },
    component: () => import('@school/pages/embeds/Button.vue'),
  },
  {
    path: '/packs/:slug/embed/card',
    name: 'bundleembed_card',
    meta: { layout: 'Blank', embed: true },
    component: () => import('@school/pages/embeds/Card.vue'),
  },
  {
    path: '/mon-espace',
    name: 'dashboard',
    meta: { checkAuth: true, layout: 'App' },
    component: () => import('@school/pages/Dashboard.vue'),
  },
  {
    path: '/mon-espace/formations/:slug',
    name: 'mytraining',
    meta: { checkAuth: true, layout: 'App' },
    component: () => import('@school/pages/MyTraining.vue'),
  },
  {
    path: '/mon-espace/formations/:slug/elements/:id',
    name: 'mytrainingitem',
    meta: { checkAuth: true, layout: 'TrainingItem' },
    component: () => import('@school/pages/MyTrainingItem.vue'),
  },
  {
    path: '/mon-espace/compte',
    meta: { checkAuth: true },
    component: () => import('@school/pages/Account.vue'),
    children: [
      {
        path: '',
        meta: { checkAuth: true, layout: 'App' },
        name: 'account',
        component: () => import('@school/pages/account/Index.vue'),
      },
      {
        path: 'securite',
        meta: { checkAuth: true, layout: 'App' },
        name: 'account_security',
        component: () => import('@school/pages/account/Security.vue'),
      },
      {
        path: 'facturation',
        meta: { checkAuth: true, layout: 'App' },
        name: 'account_billing',
        component: () => import('@school/pages/account/Billing.vue'),
      },
      {
        path: 'notifications',
        meta: { checkAuth: true, layout: 'App' },
        name: 'account_notifications',
        component: () => import('@school/pages/account/Notifications.vue'),
      },
    ],
  },
  {
    path: '/affilie',
    meta: { checkAuth: true },
    component: () => import('@school/pages/Affiliate.vue'),
    children: [
      {
        path: '',
        meta: {
          checkAuth: true,
          layout: 'App',
          smallContainer: false,
        },
        name: 'affiliate',
        component: () => import('@school/pages/affiliate/Index.vue'),
      },
      {
        path: 'profil',
        meta: {
          checkAuth: true,
          layout: 'App',
          smallContainer: false,
        },
        name: 'affiliate_profile',
        component: () => import('@school/pages/affiliate/Profile.vue'),
      },
      {
        path: 'paiements',
        meta: {
          checkAuth: true,
          layout: 'App',
          smallContainer: false,
        },
        name: 'affiliate_payouts',
        component: () => import('@school/pages/affiliate/Payouts.vue'),
      },
    ],
  },
  community,
  {
    path: '*',
    component: () => import('@school/pages/404.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    return { x: 0, y: 0 };
  },
});

export default router;
